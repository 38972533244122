<template>
  <div class="page request-appartement-page typo">

    <!-- Meldungen -->
     <div
      v-if="$store.state.requestAppartement.result > 0"
      class="row">
        <div
          :class="{
            'success': $store.state.requestAppartement.result === 1,
            'error': $store.state.requestAppartement.result >= 10
          }"
          class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text msg">
            <p v-if="$store.state.requestAppartement.result === 1">
              Vielen Dank für Ihre Buchungsanfrage. Wir werden uns so schnell wie möglich mit Ihnen 
              in Verbindung setzen und Ihnen ein Angebot schicken.
            </p>
            <p v-if="$store.state.requestAppartement.result === 10">
              Es tut uns leid, ein unbekannter Fehler ist aufgetreten. Ihre Anfrage wurde nicht 
              übermittelt. Bitte versuchen Sie es erneut oder setzen sich direkt mit uns in Verbindung.
            </p>
            <p v-if="$store.state.requestAppartement.result === 11">
              Ihre Angaben sind unvollständig. Bitte prüfen Sie die markierten Felder und schicken 
              das Formular erneut ab. Vielen Dank.
            </p>
        </div>
    </div>

    <sections-elem :content="$store.state.page.content.sections"></sections-elem>

    <!-- Persönliche Angaben -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Persönliche Angaben</h3>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.salutation.required">
            Anrede
        </label-control>
      </div>
      <div class="col is-2 is-8-sm control">
        <select-control
          v-model="$store.state.requestAppartement.data.salutation.value"
          :options="$store.state.requestAppartement.data.salutation.options"
          :error="$store.state.requestAppartement.data.salutation.error">
        </select-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.prename.required">
            Vorname
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestAppartement.data.prename.value"
          :error="$store.state.requestAppartement.data.prename.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.surname.required">
            Name
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestAppartement.data.surname.value"
          :error="$store.state.requestAppartement.data.surname.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.street.required">
            Straße
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestAppartement.data.street.value"
          :error="$store.state.requestAppartement.data.street.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.zip.required">
            PLZ
        </label-control>
        <label-control
          :required="$store.state.requestAppartement.data.town.required"
          class="is-hidden-sm">
            Ort
        </label-control>
      </div>
      <div class="col is-1 is-8-sm control">
        <text-control
          v-model="$store.state.requestAppartement.data.zip.value"
          :error="$store.state.requestAppartement.data.zip.error">
        </text-control>
      </div>
      <div class="col is-4 is-hidden-sm control">
        <text-control
          v-model="$store.state.requestAppartement.data.town.value"
          :error="$store.state.requestAppartement.data.town.error">
        </text-control>
      </div>
    </div>
    <div class="row is-hidden-lg-only is-hidden-md-only">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.town.required">
            Ort
        </label-control>
      </div>
      <div class="col is-8-sm control">
        <text-control
          v-model="$store.state.requestAppartement.data.town.value"
          :error="$store.state.requestAppartement.data.town.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.phone.required">
            Telefon
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestAppartement.data.phone.value"
          :error="$store.state.requestAppartement.data.phone.error">
        </text-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.email.required">
            E-Mail
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <text-control
          v-model="$store.state.requestAppartement.data.email.value"
          :isEmail="true"
          :error="$store.state.requestAppartement.data.email.error">
        </text-control>
      </div>
    </div>

    <!-- Buchungsperiode -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Buchungszeitraum</h3>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.arrivalDate.required">
            Anreise Datum
        </label-control>
        <label-control
          :required="$store.state.requestAppartement.data.arrivalTime.required"
          class="is-hidden-sm">
            Uhrzeit
        </label-control>
      </div>
      <div class="col is-2 is-8-sm control">
        <date-control
          v-model="$store.state.requestAppartement.data.arrivalDate.value"
          :min="periodStart"
          :error="$store.state.requestAppartement.data.arrivalDate.error">
        </date-control>
      </div>
      <div class="col is-2 is-hidden-sm control">
        <select-control
          v-model="$store.state.requestAppartement.data.arrivalTime.value"
          :options="$store.state.requestAppartement.data.arrivalTime.options"
          :error="$store.state.requestAppartement.data.arrivalTime.error">
        </select-control>
      </div>
    </div>
    <div class="row is-hidden-lg-only is-hidden-md-only">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.arrivalTime.required">
            Uhrzeit
        </label-control>
      </div>
      <div class="col is-8-sm control">
        <select-control
          v-model="$store.state.requestAppartement.data.arrivalTime.value"
          :options="$store.state.requestAppartement.data.arrivalTime.options"
          :error="$store.state.requestAppartement.data.arrivalTime.error">
        </select-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.departureDate.required">
            Abreise Datum
        </label-control>
        <label-control
          :required="$store.state.requestAppartement.data.departureTime.required"
          class="is-hidden-sm">
            Uhrzeit
        </label-control>
      </div>
      <div class="col is-2 is-8-sm control">
        <date-control
          v-model="$store.state.requestAppartement.data.departureDate.value"
          :min="periodEnd"
          :error="$store.state.requestAppartement.data.departureDate.error">
        </date-control>
      </div>
      <div class="col is-2 is-hidden-sm control">
        <select-control
          v-model="$store.state.requestAppartement.data.departureTime.value"
          :options="$store.state.requestAppartement.data.departureTime.options"
          :error="$store.state.requestAppartement.data.departureTime.error">
        </select-control>
      </div>
    </div>
    <div class="row is-hidden-lg-only is-hidden-md-only">
      <div class="col is-4 label">
        <label-control
          :required="$store.state.requestAppartement.data.departureTime.required">
            Uhrzeit
        </label-control>
      </div>
      <div class="col is-8 control">
        <select-control
          v-model="$store.state.requestAppartement.data.departureTime.value"
          :options="$store.state.requestAppartement.data.departureTime.options"
          :error="$store.state.requestAppartement.data.departureTime.error">
        </select-control>
      </div>
    </div>

    <!-- Übernachtungen -->
    <div class="row">
      <div class="col is-5 is-offset-4 is-8-sm is-offset-4-sm is-12-xs is-no-offset-xs info">
        <p v-if="periodDaysError === 2" class="has-error">
          Frühstes Anreisedatum ist der {{ periodStartString }}.
        </p>
        <p v-if="periodDaysError === 3" class="has-error">
          Das Abreisedatum muss nach dem Anreisedatum liegen.
        </p>
        <p v-if="periodDaysError === 4" class="has-error">
          Maximal {{ $store.state.page.calc.periodmax.value }} Übernachtungen sind möglich. Bitte korrigieren Sie An- oder Abreisedatum.
        </p>
        <p v-if="!periodDaysError">
          <strong>{{ $tc('nights', periodDays) }}</strong>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col is-5 is-offset-4 is-8-sm is-offset-4-sm is-12-xs is-no-offset-xs info">
        Abweichende An- und Abreisezeiten nach Vereinbarung.
      </div>
    </div>

    <!-- Appartement -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
        <h3 class="legend">Personen und Appartement</h3>
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.personsCount.required">
            Anzahl Personen
        </label-control>
      </div>
      <div class="col is-1 is-2-sm control">
        <number-control
          v-model="$store.state.requestAppartement.data.personsCount.value"
          :min="$store.state.requestAppartement.data.personsCount.min"
          :max="personsMax"
          :error="$store.state.requestAppartement.data.personsCount.error">
        </number-control>
      </div>
    </div>
    <div
      v-for="(option, i) in $store.state.requestAppartement.data.appartement.options"
      :key="i"
      class="row">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm control">
          <radio-control
            v-model="$store.state.requestAppartement.data.appartement.value"
            :options="[option]"
            :error="$store.state.requestAppartement.data.appartement.error">
          </radio-control>
        </div>
        <div class="col is-2 is-3-sm info">
          {{ option.baseprice | currency }} / Nacht
        </div>
        <div class="col is-2 is-3-sm info">
          {{ option.personsmax }} Personen
        </div>
        <div class="col is-2 info">
          <link-elem :link="option.link">Details</link-elem>
        </div>
    </div>

    <!-- Preisberechnung -->
    <div v-if="validCalculation">
      <div class="row">
        <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
          <hr />
          <h3 class="legend">Preisberechnung</h3>
        </div>
      </div>
      <div class="row is-xs calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          Appartement
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs">
          {{ calculation.rental.count }} Übernachtungen à {{ calculation.rental.single | currency }}
        </div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.rental.total | currency }}</strong>
        </div>
      </div>
      <div class="row is-xs calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          Bettzeug
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs">
          {{ calculation.linen.count }} Personen à {{ calculation.linen.single | currency }}
        </div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.linen.total | currency }}</strong>
        </div>
      </div>
      <div class="row is-xs calculation">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          Endreinigung
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs"></div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.cleaning.total | currency }}</strong>
        </div>
      </div>
      
      <div class="row is-xs calculation sum has-border">
        <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm is-7-xs">
          Gesamtpreis
        </div>
        <div class="col is-5 is-4-sm is-hidden-xs"></div>
        <div class="col is-2 is-4-sm is-5-xs total">
          <strong>{{ calculation.sum.total | currency }}</strong>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
          <hr />
          <h3 class="legend">Preise</h3>
        </div>
      </div>
      <div class="row">
          <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text">
            <p>Bitte geben Sie zunächst den <strong>Buchungszeitraum</strong> und die <strong>Anzahl Personen</strong> ein.</p>
          </div>
      </div>
    </div>

    <!-- Abschlusstext -->
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col is-3 is-offset-1 is-4-sm is-no-offset-sm label">
        <label-control
          :required="$store.state.requestAppartement.data.message.required">
            Ihre Nachricht
        </label-control>
      </div>
      <div class="col is-5 is-8-sm control">
        <textarea-control
          v-model="$store.state.requestAppartement.data.message.value"
          :error="$store.state.requestAppartement.data.message.error">
        </textarea-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm formgroup text">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm text">
        <text-elem :text="$store.state.page.content.submittext"></text-elem>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm control">
        <checkbox-control
          v-model="$store.state.requestAppartement.data.conditions.value"
          :error="$store.state.requestAppartement.data.conditions.error">
            <text-elem :text="$store.state.page.content.conditionstext"></text-elem>
        </checkbox-control>
      </div>
    </div>
    <div class="row">
      <div class="col is-10 is-offset-1 is-12-sm is-no-offset-sm action">
        <button-elem
          @click="onSubmit($event)"
          :spinner="submitProgress"
          class="is-medium is-outline">
            {{ $store.state.page.content.button.value }}
        </button-elem>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Scroll from '@/services/Scroll'
import TextControl from '@/components/controls/Text'
import DateControl from '@/components/controls/Date'
import NumberControl from '@/components/controls/Number'
import RadioControl from '@/components/controls/Radio'
import TextareaControl from '@/components/controls/Textarea'
import SelectControl from '@/components/controls/Select'
import CheckboxControl from '@/components/controls/Checkbox'
import LabelControl from '@/components/controls/Label'

export default {
  name: 'request-appartement-page',
  components: {
    TextControl,
    DateControl,
    NumberControl,
    RadioControl,
    TextareaControl,
    SelectControl,
    CheckboxControl,
    LabelControl
  },
  data () {
    return {
      submitProgress: false
    }
  },
  created () {
    this.$store.commit('requestAppartement/initForm', this.$store.state.page.content.appartements)
  },
  computed: {
    ...mapGetters({
      periodStart: 'requestAppartement/periodStart',
      periodEnd: 'requestAppartement/periodEnd',
      periodDays: 'requestAppartement/periodDays',
      periodDaysError: 'requestAppartement/periodDaysError',
      personsMax: 'requestAppartement/personsMax',
      calculation: 'requestAppartement/calculation'
    }),
    periodStartString () {
      return fn.dateToString(this.periodStart, true)
    },
    validCalculation () {
      return this.calculation !== false
    }
  },
  methods: {
    onSubmit (Event) {
      this.submitProgress = true
      this.$store.dispatch('requestAppartement/submit')
      .then(() => {
        this.$nextTick(() => {
          Scroll.smooth(0)
          this.submitProgress = false
        })
      })
    }
  }
}
</script>

<style lang="sass">
.request-appartement-page
  .msg
    margin-bottom: m(2)
    padding: m(2) m(3)
    p
      color: $white
    &.error
      background-color: $warning-color
    &.success
      background-color: $success-color
  .label-control
    &+.label-control
      &:before
        margin-left: m(1)
        margin-right: m(1)
        content: "/"
  .action
    padding-top: m(4)
    text-align: center
  p
    &.has-error
      color: $red
  .calculation
    .total,
    .info
      text-align: right
    &.has-border
      margin-top: m(1)
      .col
        padding-top: m(2)
        border-top: 1px solid $border-color

+xs
  .request-appartement-page
    .col
      &.label
        padding-bottom: 0
      &.control
        padding-top: 0
    .calculation
      .info
        text-align: left
</style>